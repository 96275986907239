@import "../../styles/utilities.scss";

.connected-wallet-info-section {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 16px;
  &:not(:last-of-type) {
    border-bottom: 1px solid var(--color-border);
  }

  .info-section-item {
    display: grid;
    grid-template-columns: 150px 1fr;
    grid-auto-flow: row;
    column-gap: 8px;
    align-items: flex-start;
    font-size: 14px;

    &__value {
      color: #6b7280;
      @extend .wrap-text;
    }
  }
}
