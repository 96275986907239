.centered {
  max-width: 1200px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.text-link {
  color: var(--color-link) !important;
}

.link {
  @extend .text-link;
  cursor: pointer !important;
}

.card-border {
  border: 1px solid var(--color-border) !important;
}

.wrap-text {
  word-wrap: break-word;
  overflow: hidden;
}
