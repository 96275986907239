.settings__title {
  font-size: 20px;
  margin-bottom: -4px;
  text-shadow: 0 2px white;
  color: #afb2b4;
}

.settings-item {
  padding: 16px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2px;
  }

  &__title {
    font-size: 14px;
    font-weight: 500;
  }

  &__content {
    font-size: 12px;
    max-width: 84%;
    color: var(--color-text-1);
  }
}
