@import "./sizes.scss";

.w-constraint {
  max-width: $page-max-w;
  width: 100%;
  margin: auto;
}

.modal-header {
  font-size: 20px;
  border-bottom: 1px solid var(--color-border);
  padding-bottom: 8px;
  margin-bottom: 24px;
}

.modal-footer {
  border-top: none;
  padding-top: 16px;
  margin-top: 16px;
}

.fullscreen-enabled {
  background: var(--color-bg);
}

.Widget {
  height: 400px;
  width: 100%;
  background-color: var(--color-bg);
  border-radius: 8px;
  border: 1px solid var(--color-border);
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
}

.WidgetHeader {
  height: 32px;
  display: flex;
  align-items: center;
  font-weight: 500;
  text-transform: uppercase;
}

.WidgetFooter {
  flex: 0 0 44px;
  display: flex;
  align-items: center;
  padding: 0px 16px;
  border-top: 1px solid var(--color-border-1);
}

.WidgetPaginator {
  .MuiTablePagination-toolbar {
    min-height: 0 !important;
  }

  .MuiButtonBase-root {
    padding: 0 !important;
  }

  .MuiTablePagination-actions {
    margin-left: 8px !important;
  }

  .MuiTablePagination-displayedRows {
    font-size: 13px !important;
  }
}

.WidgetList {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .header {
    padding: 4px 0;
    font-size: 11px;
    text-transform: uppercase;
    color: var(--color-text-2);
    border-bottom: 1px solid var(--color-border-1);
  }

  .row {
    padding: 6px 0 4px;
    &:not(:last-of-type) {
      border-bottom: 1px solid var(--color-border-1);
    }
    &:hover {
      background-color: var(--color-hover);
    }
  }
}

.WidgetSelect {
  .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }

  .MuiSelect-select {
    font-size: 13px;
  }

  .MuiSelect-select {
    padding: 0;
  }
}
