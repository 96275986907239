.opp-info-section {
  display: flex;
  flex-direction: column;
  gap: 12px;
  &:not(:last-of-type) {
    padding-bottom: 16px;
    border-bottom: 1px solid var(--color-border);
  }

  .info-section-item {
    display: grid;
    grid-template-columns: 170px 1fr;
    grid-auto-flow: row;
    column-gap: 8px;
    align-items: flex-start;
    font-size: 14px;
    padding: 0 8px;
    color: var(--color-text-1);

    &__value {
      color: var(--color-text);
    }
  }
}
