.MuiDataGrid-cell,
.MuiDataGrid-columnHeader {
  &:focus,
  &:focus-within {
    outline: none !important;
  }
}

.MuiDataGrid-columnHeaders {
  user-select: none !important;
}

.MuiDataGrid-columnSeparator--sideRight {
  display: none !important;
}

.MuiDataGrid-root,
.MuiPaper-root {
  background-color: var(--color-bg);
}

.MuiCircularProgress-root {
  position: relative;
  z-index: 1000;
}
