@import "../../../styles/utilities.scss";

.smart-deal-info-section {
  padding-bottom: 16px;
  &:not(:last-of-type) {
    border-bottom: 1px solid var(--color-border);
  }

  .info-section-item {
    display: grid;
    grid-template-columns: 250px 1fr;
    grid-auto-flow: row;
    column-gap: 8px;
    align-items: flex-start;
    font-size: 14px;

    &__value {
      color: #6b7280;
      @extend .wrap-text;
    }
  }
}

.smart-deal-payment-info-section {
  @extend .smart-deal-info-section;

  .info-section-item {
    grid-template-columns: 240px 1fr;
  }
}

.smart-deal-prompt-pay-info-section {
  @extend .smart-deal-info-section;
  @apply flex flex-col gap-4;

  .info-section-item {
    grid-template-columns: 160px 1fr;
  }
}

.BlockchainEvents {
  .events-list-row {
    @apply flex items-start gap-4 pb-2 text-sm;
    &:not(:last-child) {
      border-bottom: 1px solid var(--color-border-1);
    }
    &:first-child {
      @apply text-text-1;
    }

    &__col-type {
      flex: 0 0 120px;
    }
    &__col-data {
      @extend .wrap-text;
      flex: 1;
    }
  }
}

.sdd-info-section {
  display: flex;
  flex-direction: column;
  gap: 16px;
  &:not(:last-of-type) {
    padding-bottom: 16px;
    border-bottom: 1px solid var(--color-border);
  }

  .info-section-item {
    display: grid;
    grid-template-columns: 170px 1fr;
    grid-auto-flow: row;
    column-gap: 8px;
    align-items: flex-start;
    font-size: 14px;
    padding: 0 8px;
    color: var(--color-text-1);

    &__value {
      @extend .wrap-text;
      color: var(--color-text);
    }
  }
}

.SmartDealPaymentFlow {
  .MuiTimeline-root {
    @apply p-0;
  }

  .MuiTimelineContent-positionLeft {
    @apply pl-0;
  }

  .MuiTimelineOppositeContent-root {
    @apply flex-[0_0_100px] pl-0 opacity-90 text-xs;
  }

  .MuiTimelineContent-positionRight {
    min-width: 400px;
  }
}

.DocumentBlockchainFlow {
  .MuiTimeline-root {
    @apply p-0;
  }

  .MuiTimelineContent-positionLeft {
    @apply pl-0;
  }

  .MuiTimelineOppositeContent-root {
    @apply flex-[0_0_180px] pl-0 opacity-90 text-sm;
  }
}
