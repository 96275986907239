* {
  scrollbar-width: thin;
  scrollbar-color: var(--color-bg-1);

  &::-webkit-scrollbar {
    width: 0.3125rem;
    height: 0.3125rem;
  }

  &::-webkit-scrollbar-track {
    background: var(--color-bg);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--color-primary);
  }
}
