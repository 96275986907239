:root {
  --color-primary: #8791a7;
  --color-secondary: #17a2b8;
  --color-bg: #fff;
  --color-bg-1: #f7f7f7;
  --color-border: #e0e0e0;
  --color-border-1: #ececec;
  --color-blue: #0288d1;
  --color-link: #0779b2;
  --color-green-light: #39db80;
  --color-red-light: #fd5982;
  --color-text: rgba(0, 0, 0, 0.85);
  --color-text-1: rgba(0, 0, 0, 0.67);
  --color-text-2: rgba(0, 0, 0, 0.54);
  --color-danger: #ff0000;
  --color-hover: rgba(0, 0, 0, 0.1);
}
