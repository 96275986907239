.LoadingOverlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
      @supports (backdrop-filter: blur(1px)) or
        (--webkit-backdrop-filter: blur(1px)) {
        & {
          backdrop-filter: blur(1px);
          --webkit-backdrop-filter: blur(1px);
        }
      }
}