@import "../../../styles/components.scss";

.Dashboard {
  @extend .w-constraint;
  display: grid;
  grid-template-columns: 3fr 2fr 2fr;
  grid-template-rows: auto;
  gap: 16px;

  @media screen and (max-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}

.PlanSubscriptions {
  .grid {
    display: grid;
    grid-template-columns: 4fr repeat(3, 5fr);
    gap: 2px;
  }
  .cell {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
  }
}
