@import "../../../styles/components.scss";

.TreasuryManagement {
  @extend .w-constraint;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: auto;
  gap: 16px;

  .Widget {
    height: 360px;
  }

  .WidgetHeader {
    height: 24px;
  }

  @media screen and (max-width: 1100px) {
    grid-template-columns: 1fr;
  }

  .text-buy {
    color: #239e50;
  }

  .text-sell {
    color: #ef4444;
  }

  .text-convert {
    color: #bf9003;
  }

  .filter-badge {
    .MuiBadge-badge {
      right: 4px;
      top: 6px;
    }
  }
}
