.OpportunityUnderReviewNotification {
  ul {
    @apply mt-2 ml-2 flex flex-col gap-2 list-disc list-inside;

    li {
      .key {
        @apply mr-3 opacity-70;
      }
    }
  }
}
