.Tabs {
    .MuiToggleButton-root {
        border: none;
        border-radius: 0;
        text-transform: none;

        &.Mui-selected {
            background: none;
            color: initial;
            border-top: 2px solid var(--color-secondary);
        }
    }
}