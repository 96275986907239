@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./styles";
@import "react-multi-carousel/lib/styles.css";

body,
html {
  font-family: "Roboto", sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  height: 100vh;
}
